"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const parseAccountNumber = (value) => {
    if (!value) {
        return '';
    }
    const onlyNumbersAndDash = value.replace(/[^\d-]/g, '');
    if (onlyNumbersAndDash.length > 16) {
        return onlyNumbersAndDash.slice(0, 16);
    }
    return onlyNumbersAndDash;
};
exports.default = parseAccountNumber;
